@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --global-transition: 0.25s ease;
  --global-theme: #2560fc;
  --global-background: #r;
  --global-footer: #343f53;
  --global-border-radius: 12px;
  --external-primary-color--: #0277fa;
}

* {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
}

body {
  padding-right: 0px !important;
}

.swiper-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

/* Swiper Buttons adjustment */
.swiper-button-next {
  /* button styles */
  margin-left: 100px;
}

/* input tag width for number in login form  */
.react-tel-input .form-control {
  width: 350px;
}

.nav-link.active {
  text-decoration: underline;
}

.css-1oqqzyl-MuiContainer-root {
  padding: 0px;
}

.css-12vja52-MuiToolbar-root {
  padding-left: 0;
  padding-right: 0;
}

.css-m1uy8a-MuiPaper-root-MuiCard-root {
  box-shadow: 0;
}

/*Logout style*/
.logout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  width: 100%;
  border-radius: 10px;
}

.logout-title {
  font-size: 32px;
  color: #333;
}

.logout-message {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 261px;
  background: #0000003b;
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 212, 255, 0) 57%);
  border-radius: inherit;
}

.service-card-rating {
  position: absolute;
  top: 12px;
  right: 12px;
  color: white;
  border-radius: inherit;
  /* background: #009d3e; */
  /* padding: 5px 10px; */
}

.service-card {
  transition: var(--global-transition) !important;
  cursor: pointer;
  height: 319px;
  width: 100%;
  margin-bottom: 1414px14pxpx;
  margin-top: 14px;
}

.service-card:hover {
  transform: scale(1.05);
  border-radius: 10px;
  transition: var(--global-transition);
}

/* add this swiper only to category not main home page section  */
.swiper-wrapper-padding {
  padding: 8px;
}

.css-o69gx8-MuiCardMedia-root {
  object-fit: cover;
}

hr {
  color: #c8b4b430;
  height: 0.1px;
}

img {
  cursor: pointer;
}

.creative_category:hover {
  border: 1px solid #4566d3;
  border-radius: 4px;
}

.css-1ivbu65-MuiSvgIcon-root {
  fill: currentColor;
  font-size: 1.5rem;
  color: white;
  height: 25px;
  width: 25px;
}

/* svg {
  height: 20px;
  width: 20px;
} */

.css-10mi8st-MuiSvgIcon-root-MuiAvatar-fallback {
  color: white;
}

.myinput {
  height: 25px !important;
  line-height: 25px !important;
  padding-top: 0 !important;
  /* padding-left: 1px !important; */
  /* padding-right: 160px !important; */
  caret-color: #fc8019;
  width: 500px;
  font-size: 18px !important;
  font-weight: 500;
}

.footer-item:hover {
  color: var(--external-primary-color--);
}

.c-link {
  color: var(--external-primary-color--) !important;
}

.myPaystrack {
  background: none;
  border: none;
}

.phoneinput {
  height: 24px !important;
  line-height: 28px !important;
  padding-top: 0 !important;
  /* padding-left: 1px !important; */
  /* padding-right: 160px !important; */
  caret-color: #fc8019;
  width: 200px;
  font-size: 18px !important;
  font-weight: 500;
}

.active-navlink {
  transform: translateX(10px);
  /* Adjust the translateX value as needed */
  transition: transform 0.2s ease;
  /* Adjust the transition duration and easing as needed */
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 9px;
  margin-left: 61px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 35px;
  height: 48px;
  width: 323px;
  outline: none;
}

.flag-dropdown {
  border-radius: 4px;
  width: 48px;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border: 1px solid #cacaca;
  border-radius: 4px;
}

.country-list {
  position: absolute;
  top: 100%;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 354px;
  padding: 20px;
  align-items: baseline;
  flex-wrap: nowrap;
}

.opt-container {
  margin-left: 5px;
}

/* start-page ui  */
#wrapper {
  display: table;
  margin: auto;
}

#wrapper>div {
  display: inline-block;
}

.line-top {
  width: 120px;
  height: 60px;
  z-index: 1;
  background: transparent;
  border: none;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: medium;
  border-top: dashed 2px black;
  padding: 93px 96px;
  border-radius: 50%;
  margin: 20px -50px 0;
}

.line-bottom {
  width: 120px;
  height: 60px;
  z-index: 1;
  background: transparent;
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-bottom: dashed 2px black;
  padding: 125px 109px;
  border-radius: 50%;
  margin: -48px -78px;
}

.imgOverlay {
  color: black;
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 1;
  background: #00000040;
  border-radius: 106px;
  text-align: center;
  justify-content: center;
  color: white;
}

.startimg {
  filter: brightness(0.5);
}

.startimg:hover {
  filter: brightness(1);
  /* Increase brightness to 120% on hover */
}

.line-left {
  width: 120px;
  height: 60px;
  z-index: 1;
  background: transparent;
  border: none;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: medium;
  border-left: dashed 2px black;
  padding: 102px 94px;
  border-radius: 50%;
  margin: -204px -6px 0;
}

.line-right {
  width: 128px;
  height: 60px;
  z-index: 1;
  background: transparent;
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-right: dashed 2px black;
  padding: 96px 106px;
  border-radius: 50%;
  margin: -152px -19px 0px;
}

.css-1bcxhx3-MuiPaper-root {
  z-index: 1;
}

.button-layout {
  background: var(--global-theme);
  max-width: 220px;
  padding: 16px;
  color: white;
  border-radius: 10px;
  margin-left: 64px;
}

.start-page {
  background-color: var(--global-background);
}

/* calender grid spacing  */
.css-flbe84-MuiDayCalendar-weekContainer {
  gap: 26px;
}

/* calender heading spacign */
.css-i5q14k-MuiDayCalendar-header {
  gap: 26px;
}

/* calender week name  */
.css-i5q14k-MuiDayCalendar-header {
  color: var(--global-theme);
}

.start-images {
  display: block;
}

.css-1rbtq2c-MuiPaper-root {
  box-shadow: none;
}

.drawer-button {
  height: 40.5px;
  width: 220px;
}

.css-pmfyfa-MuiGrid-root {
  box-sizing: border-box;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  box-shadow: none;
  border-radius: 10px;
}

@media (min-width: 1536px) {
  .css-1b3l6lk-MuiGrid-root {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

.booking-information {
  padding-top: 3px;
  padding-bottom: 3px;
}

/* calender  */

.react-calendar {
  width: "100%";
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 12px;
}

.react-calendar__month-view__weekdays__weekday {
  color: var(--global-theme);
}

.react-calendar__tile--now {
  border: 1px solid black;
  background: rgb(224, 224, 224);
  border-radius: 50%;
  padding: 5px;
  /* Adjust as needed */
  margin: 0 auto;
  /* Center the circle horizontally */
}

.react-calendar__tile--active {
  background: var(--global-theme);
  color: white;
  border-radius: 50%;
  padding: 5px;
  /* Adjust as needed */
  margin: 0 auto;
  /* Center the circle horizontally */
}

.react-calendar__navigation {
  display: flex;
  height: 62px;
}

.react-calendar__month-view__weekdays {
  height: 40px;
}

.react-calendar__tile--rangeEnd .react-calendar__tile--rangeBothEnds {
  padding: 10px;
  margin-left: 10px;
}

.select-calendar-heading {
  background-color: var(--global-theme);
  margin-left: 10px;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #006edc;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-calendar button {
  padding: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiBox-root .css-1133hb8 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paypal-powered-by {
  display: none;
}

.paypal-button-color-black {
  display: none;
}

.css-1rbtq2c-MuiPaper-root {
  box-shadow: none;
}

.paypal-button.paypal-button-color-black,
.paypal-button-row.paypal-button-color-black .menu-button {
  display: none;
}

.paypal-powered-by {
  display: none;
}

.booking-button {
  margin-top: 1px;
  margin-right: 1px;
  color: var(--global-theme);
}

.css-1f5kqnz-MuiButtonBase-root-MuiButton-root {
  color: var(--global-theme);
}

.booking-box {
  border: 1px solid #dedddd;
  border-radius: 10px;
  margin-bottom: 10px;
}

.provider_links {
  text-align: center;
  justify-content: center;
  display: flex;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: -10px;
}

.swiper-button-next {
  background: #f2f1f6;
  padding-inline: 5px;
  padding-block: 20px;
  margin-left: 6px;
  border-radius: 10px;
  right: -7px !important;
  color: black !important;
}

.swiper-button-prev {
  background: #f2f1f6;
  padding-inline: 5px;
  padding-block: 20px;
  margin-left: 6px;
  border-radius: 10px;
  left: -12px !important;
  margin-top: -38px;
  color: black !important;
}

.css-jpln7h-MuiTabs-scroller {
  position: relative;
  display: inline-block;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow-x: auto;
  width: 100%;
}

.css-heg063-MuiTabs-flexContainer {
  overflow: auto;
}

.backdrop {
  z-index: 100;
}

.footer {
  background: var(--global-footer);
  color: white;
  padding: 20px;
  font-size: 14px;
}

.footer-item {
  text-decoration: none;
  color: white;
  padding-bottom: 15px;
}

.textfield-footer {
  border-radius: 10px;
  margin-top: 10px;
}

.insta-icon {
  z-index: 8px;
  color: #111;
  padding: 4px;
  background: white;
  padding: 2px;
  margin-right: 20px;
  border: 1px solid white;
}

.insta-icon:hover {
  color: #fff;
  background: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
}

.whatsapp-icon {
  z-index: 8px;
  color: #111;
  padding: 4px;
  background: white;
  padding: 2px;
  margin-right: 20px;
  border: 1px solid white;
}

.whatsapp-icon:hover {
  color: #fff;
  background: #25d366;
}

.footer-container {
  text-align: center;
  margin-top: 16px;
}

.navigation-burger {
  width: 166px;
  margin-left: -11px;
}

.logo {
  font-size: 20px;
  margin-left: -30px;
}

.address-form {
  margin-bottom: 16px;
}

.add-address-btn {
  margin-top: 16px;
}

.drawer-button-address {
  height: 100px;
}

.icon {
  height: 30px;
  width: 30px;
}

.promocode-btn.css-1vpygk1-MuiButtonBase-root-MuiButton-root {
  height: 60px;
  background: #343f53;
}

.mt-1 {
  margin-top: 8px;
}

.booking-info-table {
  min-width: 300px;
}

.place-order-btn {
  height: 54px;
}

.lower-case-btn {
  text-transform: none;
}

.border-radius-2 {
  border-radius: 16px;
}

.edemand-flexgrow {
  flex-grow: 8px;
}

.color-white {
  color: white !important;
}

.ml-auto {
  margin-left: auto;
}

.mt-3-h-100 {
  margin-top: 24px;
  height: 100px;
}

.z-100 {
  z-index: 100;
}

.mt-m1 {
  margin-top: -8px;
}

.m-1-w-52 {
  margin: 8px;
  /* width: 52ch; */
}

.address-btn {
  margin-top: 8px;
  padding: 10px 20px;
  margin-left: 16px;
}

.w-350 {
  width: 350px;
}

.mt-1-mb-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.pt-1-pb-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mr-2 {
  margin-right: 16px;
}

.mr-3 {
  margin-right: 24px;
}

.minwidth-650 {
  min-width: 650px;
}

.background-theme {
  background: var(--global-theme);
}

.textaling-start {
  text-align: start;
}

.color-green {
  color: green;
}

.completed-booking {
  color: green;
  border: 1px solid green;
}

.awaiting-booking {
  color: gray;
  border: 1px solid gray;
}

.cancle-booking {
  color: red;
  border: 1px solid red;
}

.tabs {
  gap: 16px;
  overflow: auto;
}

.w-124 {
  width: 124px;
}

.w-130 {
  width: 130px;
}

.w-134 {
  width: 134px;
}

.mr-1 {
  margin-right: 8px;
}

.button-background {
  background: #343f53;
}

.flex-margin {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

.border-radius-1 {
  border-radius: 8px;
}

.card-logo {
  border-radius: 100px;
  border: 5px solid white;
  margin-left: -24px;
  margin-top: 48px;
}

.mr-auto {
  margin-right: auto;
}

.mb-1 {
  margin-bottom: 8px;
}

.ml-1 {
  margin-left: 8px;
}

.minHeight-550 {
  min-height: 550px;
  margin-top: 80px;
}

.max-w-200 {
  max-width: 200px;
}

.aling-self-end {
  align-self: flex-end;
}

.card-text {
  text-align: center;
  margin-top: -48px;
}

.container-mt {
  margin-top: 80px;
}

.contact-form {
  background-color: #f2f1f6;
  margin-bottom: 32px;
}

.send-form {
  margin-top: 16px;
  float: right;
  background: #343f53;
}

.mb-6 {
  margin-bottom: 48px;
}

.back-f2f1 {
  background: #f2f1f6;
}

.avatar {
  height: 50px;
  width: 50px;
}

.font-medium {
  font-size: medium;
  gap: 8px;
}

.aling-content {
  align-content: center;
}

.notification-container {
  margin-bottom: 24px;
}

.breadcrumb {
  cursor: pointer;
  text-decoration: none;
}

.payment-buttons {
  height: 54px;
  background: none;
}

.mb-2 {
  margin-bottom: 16px;
}

.mt-2 {
  margin-top: 16px;
}

.height-240 {
  height: 240px;
}

.provider-service-card {
  display: flex;
  box-shadow: none;
  height: auto;
  padding: 8px;
}

.ml-2 {
  margin-left: 16px;
}

.flote-right {
  float: right;
}

.height-250 {
  height: 250px;
}

.provider-service-logo {
  height: 80px;
  width: 80px;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid white;
  margin-top: -50px;
}

.jc-sa .mb-4 {
  justify-content: space-around;
  margin-bottom: 32px;
}

.fs-15 {
  font-size: 15px;
}

.bg-white {
  background: white;
}

.color-blue {
  color: var(--global-theme);
}

.border-radius-50 {
  border-radius: 50px;
}

.navLink {
  text-decoration: none;
  text-align: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
  background: #343f53;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
}

.back-343f {
  background: #343f53;
}

.gap-1 {
  gap: 8px;
}

.color-yellow {
  color: yello;
}

.border-radius-4 {
  border-radius: 4px;
}

.textaling-center {
  text-align: center;
}

.mt-m7 {
  margin-top: -7px;
}

.partner-skeleton-logo {
  border: 5px solid white;
  border-radius: 59px;
  cursor: pointer;
  margin-top: -60px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 16px;
}

.partner-skeleton-text {
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
}

.MuiCardActions-root .MuiCardActions-spacing .jc-sa .mb-4 .css-1t6e9jv-MuiCardActions-root {
  justify-content: space-around;
}

.provider-service-card1 {
  height: 180px;
  width: 200px;
  border-radius: 4px;
}

.delete-icon {
  height: 20px;
  width: 20px;
  color: red;
}

.provider-service-button {
  text-align: start;
  font-size: 15px;
  background: #323232;
}

.provider-service-button:hover {
  background: #323232;
}

.swiper-skeleton-card {
  margin-top: 24px;
  margin-bottom: 16px;
  width: 200px;
  height: 200px;
  box-shadow: none;
}

.subcat-skeleton {
  margin-top: 24px;
  width: 275px;
  height: 240px;
  box-shadow: none;
}

.subcat-card-content {
  text-align: center;
  margin-top: -7px;
}

.provider-card-skeleton {
  box-shadow: none;
  display: flex;
  border: 1px solid gray;
}

.provider-card-logo {
  margin-left: -24px;
  margin-top: 48px;
}

.subcat-img {
  height: 100%;
  width: 100% !important;
  max-width: 100%;
  justify-content: center;
  object-fit: cover;
  display: flex;
  filter: brightness(0.5);
  align-items: center;
}

.object-fit {
  object-fit: cover;
}

.swiper-paper {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 800px;
  z-index: 1;
}

.p-10 {
  padding: 10px;
}

.swiper-input {
  margin-left: 8px;
  flex: 8px;
}

.swiper-input-divider {
  height: 28px;
  margin: 0.5px;
}

.swiper-input-search {
  border: 0;
  width: 300px;
  padding-left: 8px;
}

.swiper-button {
  margin-left: 8px;
  box-shadow: 0;
  height: 38px;
  text-transform: none;
}

.w-100 {
  width: 100%;
}

.h-380 {
  height: 380px;
}

.swiper-paper-md {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 380px;
  height: 36px;
  z-index: 100;
}

.swiper-paper-sm {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  height: 36px;
  z-index: 100;
}

.flex-1 {
  flex: 8px;
}

.back-theme {
  background: var(--global-theme);
}

.start-paper {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  z-index: 100;
  width: inherit;
}

.start-button {
  box-shadow: 0;
  height: 38px;
}

.start-large {
  background: white;
  height: 600;
}

.overflow-hidden {
  overflow: hidden;
}

.max-h-100 {
  max-height: 100px;
}

.h-430 {
  height: 430px;
}

.empty-cart-img {
  width: 220px;
  border-radius: 100px;
  margin-top: 150px;
}

.add-map {
  height: 400px;
  margin-bottom: 20px;
}

.add-map-md {
  height: 460px;
  margin-top: 24px;
}

.display-none {
  display: none;
}

.swiper-height {
  height: auto;
}

.swiperslide {
  display: flex;
  justify-content: space-around;
}

.navigation-category {
  height: 309px;
  max-width: 100%;
  filter: brightness(0.8);
}

.font-weight-600 {
  font-weight: 600;
}

.border-radius-10 {
  border-radius: 10px;
}

.color-theme {
  color: var(--global-theme);
}

.display-flex {
  display: flex;
}

.just-space-between {
  justify-content: space-between;
}

.just-space-around {
  justify-content: space-around;
}

.h-auto {
  height: auto;
}

.padding-10 {
  padding: 10px;
}

.max-w-100 {
  max-width: 100%;
}

.font-w-400 {
  font-weight: 400;
}

.h-100 {
  height: 100%;
}

.border-0 {
  border: 0;
}

.m-2 {
  margin: 2;
}

.gap-12 {
  gap: 12px;
}

.partner-cardmedia {
  height: 80px;
  width: 80px;
  border: 5px solid #0277fa;
  border-radius: 50px;
  cursor: pointer;
  margin-top: -50px;
  margin-left: 35%;
  z-index: 100;
  position: relative;
}

.pt-30 {
  padding-top: 30px;
}

.font-10 {
  font-size: 10px;
}

.partenr-skeleton {
  max-width: 100%;
  border: 1px solid #f2f1f6;
  margin-top: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.max-h-500 {
  max-height: 500px;
}

.max-h-300 {
  max-height: 300;
}

.mt-m82 {
  margin-top: -82px;
}

.flex-200 {
  flex: 0 0 200px;
}

.ml-4 {
  margin-left: 32px;
}

.about-page {
  min-height: 350px;
  margin-top: 40px;
  background-color: #ffff;
}

.css-1c529vl {
  background: #111;
}

.css-1t6e9jv-MuiCardActions-root {
  justify-content: space-around;
}

.css-1fhxy4v-MuiAvatar-root {
  color: none;
  background: none;
}

.color-gold {
  color: gold;
}

.css-gg4vpm {
  align-items: center;
}

.contact-divider {
  border-left: 2px solid lightgray;
}

.pgnotfound {
  margin-top: -40px;
  height: 100vh;
  width: 100vh;
}

.pgnotfound-img {
  margin-left: 100%;
  margin-top: 150px;
}

.css-ptiqhd-MuiSvgIcon-root {
  color: white;
}

del {
  color: gray;
  font-size: 10px;
}

.pr-0 {
  padding-right: 0 !important;
}

.category-box {
  border-radius: var(--global-border-radius);
  border: 1px solid;
  text-align: center;
  cursor: pointer;
  /* border-color: #314F5D; */
}

.category-box:hover {
  color: #0277fa !important;
}

.category-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: var(--global-border-radius);
  padding: 12px;
}

.category-img-box img {
  border-radius: var(--global-border-radius);
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.highlight {
  color: var(--external-primary-color--);
  background-color: transparent;
}

.dropdown_data {
  display: flex;
  position: relative;
  background: rgb(255, 255, 255);
  padding: 1.5rem 1.4rem;
  box-shadow: none;
  filter: none;
  border-bottom: 1px dashed #838383bd;
}

.dropdown_data:last-child {
  border-bottom: none;
}

.dropdown_Sec {
  width: 100%;
  max-height: 325px;
  border-radius: 0 0 5px 5px;
  position: absolute;
  overflow: auto;
  top: 2.5rem;
  visibility: visible;
  opacity: 1;
  z-index: 99;
  box-shadow: rgb(28 28 28 / 8%) 0px 2px 8px;
  transition: opacity 0.25s ease 0s, top 0.25s ease 0s;
  padding: 0px;
}

.dropdown_Sec::-webkit-scrollbar {
  width: 5px;
}

.dropdown_Sec::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dropdown_Sec::-webkit-scrollbar-thumb {
  background: var(--primary-color--);
}

.list_Data {
  display: flex;
  position: relative;
  background: rgb(255, 255, 255);

  box-shadow: none;
  filter: none;
}

.partner_image {
  border-radius: 0.8rem;
  flex-shrink: 0;
  cursor: pointer;
  top: 0.4rem;
  height: 5rem;
  width: 5rem;
}

.partner_detail {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  width: 100%;
  flex-shrink: 1;
}

.partner_name {
  line-height: 1.5;
  margin: 0px;
  color: rgb(54, 54, 54);
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
  padding-bottom: 10px;
}

.payment-success-page {
  background-image: url("https://i.imgur.com/myImage.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.payment-success-page h1 {
  color: white;
  text-shadow: 0 0 10px black;
}

.payment-success-page button {
  background-color: white;
  color: black;
  text-shadow: 0 0 10px black;
}

.breadrumb {
  text-decoration: none !important;
}

.text-area-style {
  width: "100%";
  border-radius: "10px";
}

.modal-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4px;
}

.ratingButton {
  border-color: #111 !important;
  color: white;
}

.tab-monitor {
  background-color: white;
}

.banner_image {
  top: 301px;
  left: 170px;
  width: 497px;
  height: 228px;
  /* UI Properties */
  background: #343f5359 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.provider_service_logo {
  top: 409px;
  left: 190px;
  width: 100px;
  height: 100px;
  /* UI Properties */
  border: 2px solid var(--section-bg-color-ffffff);
  border: 2px solid #ffffff;
  border-radius: 2px;
  opacity: 1;
}

.provider_rating_text {
  top: 406px;
  display: flex;
  justify-content: left;
  left: 697px;
  width: 174px;
  height: 28px;
  /* UI Properties */
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 22px/8px
    var(--unnamed-font-family-plus-jakarta-sans);
  text-align: left;
  font: normal normal normal 18px/8px Plus Jakarta Sans;
  letter-spacing: 1.1px;
  color: #141a1f;
  opacity: 1;
}


.companyName {
  top: 356px;
  left: 697px;
  width: 300px;
  height: 30px;
  /* UI Properties */
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-24) / 8px
    var(--unnamed-font-family-plus-jakarta-sans);
  letter-spacing: var(--unnamed-character-spacing-1-2);
  text-align: left;
  font: normal normal bold 24px/8px Plus Jakarta Sans;
  letter-spacing: 1.2px;
  color: #141a1f;
  opacity: 1;
}

.our_services {
  background-color: white;
}

.about_this_provider {
  left: 170px;
  width: 223px;
  height: 30px;
  /* UI Properties */
  font: normal normal bold 24px/32px "Plus Jakarta Sans";
  color: #343f53;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

/* Assuming your modal content has a class named .modal-content */
.modal-content {
  display: flex;
  flex-wrap: wrap;
  /* Allow images to wrap to the next line */
  justify-content: space-between;
  /* Distribute space between images */
}

/* Style for individual images within the modal */
.modal-content img {
  max-width: 100%;
  /* Ensure images don't exceed their container width */
  margin-bottom: 10px;
  /* Adjust as needed to control vertical spacing */
}

/* .dropzone {
  text-align: center !important;
  padding: 20px !important;
  border: 3px dashed #eeeeee !important;
  background-color: #fafafa !important;
  color: #bdbdbd !important;
  margin-bottom: 20px !important;
} */

.custom-form-control .MuiOutlinedInput-root {
  border: 3px dashed #eeeeee !important;
}

.custom-file-input-root {
  border: 3px dashed #eeeeee !important;
}

.dropzone {
  text-align: center !important;
  padding: 20px !important;
  border: 3px dashed gray !important;
  background-color: #fafafa !important;
  color: #bdbdbd !important;
  margin-bottom: 20px !important;
}

.TextField {
  & :global(*[class$="MuiInputBase-root-MuiFilledInput-root"]:after) {
    border-bottom-color: black !important;
  }
}

/* my custom css  */

.mainContainer {
  max-width: 1638px !important;
}

.css-1wskdo2-MuiPaper-root-MuiCard-root {
  max-width: 100% !important;
}

header {
  /* position: relative;
  top: 98px; */
  position: sticky;
  top: 0px;
  z-index: 999;
  height: 90px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 16px #2121211A;
  display: flex;
  align-items: center;
}

header .navbarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .logoWrapper img {
  width: 180px;
  height: 60px;
  object-fit: contain;
}

header .linksWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 0;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  header .linksWrapper {
    gap: 30px;
  }
}

header .linksWrapper a,
.offcanvas .linksWrapper a {
  font: normal normal medium 16px/24px Plus Jakarta Sans;
  color: #343F53;
  text-decoration: none;
  font-weight: 700;
  position: relative;
}

.navActive {
  color: var(--external-primary-color--) !important;
  text-decoration: underline !important;
  text-decoration-thickness: 3px !important;
}

header .btnWrapper,
.offcanvas .btnWrapper {
  background-color: var(--external-primary-color--);
  background: var(--external-primary-color--);
  border: none;
  border-radius: 8px;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

header .btnWrapper svg,
.offcanvas .btnWrapper svg {
  font-size: 18px;
  margin: auto;
  display: block;
}

.commonBtn {
  background: var(--external-primary-color--);
  border: none;
  border-radius: 8px;
  font: normal normal medium 16px/24px Plus Jakarta Sans;
  color: #FFFFFF;
  padding: 10px;
  cursor: pointer;
}

header .rightDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

header .headerIcons {
  font-size: 26px;
  color: rgba(0, 0, 0, 0.54);
  margin: auto;
  display: block;
  cursor: pointer;
}

#hamburg {
  display: none;
}

@media screen and (max-width: 991px) {
  #hamburg {
    display: block;
  }

  .offcanvas.offcanvas-end {
    width: 64% !important;
  }

  header .centerDiv,
  header .rightDiv {
    display: none;
  }

  .offcanvas .linksWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: start;
    padding: 0px;
  }

  .offcanvas .rightDiv {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
  }

  .offcanvas .rightDiv .headerIcons {
    font-size: 30px;
  }
}

.swiper-button-prev,
.swiper-button-next {
  padding-inline: 18px !important;
  padding-block: 30px !important;
  margin-left: 6px;
  background: white !important;
  border: 4px solid #80808021;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
  font-weight: bold;
}

@media screen and (max-width: 480px) {

  .swiper-button-prev,
  .swiper-button-next {
    padding-inline: 12px !important;
    padding-block: 18px !important;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 16px !important;
  }

  .categoryNavigationBtns {
    margin: 12px 0px !important;
  }

  .categorySwiperBtn svg {
    font-size: 18px !important;
  }
}

.serviceSectCardWrapper {
  display: flex;
  gap: 30px 16px;
  flex-wrap: wrap;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .serviceSectCardWrapper {
    display: flex;
    gap: 30px 35px;
  }
  .serviceCard{
    width: 350px !important;
  }

  .providerSect .serviceCard{
    width: 100% !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .serviceSectCardWrapper {
    display: flex;
    gap: 30px 6px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .serviceCard{
    width: 322px !important;
  }
  .providerSect .serviceCard{
    width: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .serviceSectCardWrapper {
    display: flex;
    gap: 30px 44px;
  }

  /* .serviceCard {
    width: 100% !important;
  } */
}

.about-page .aboutUsContent p:last-child {
  margin: auto;
}

.breadcrumbWrapper {
  padding-top: 35px;
}

@media screen and (max-width: 575px) {

  .pageName {
    font-size: 22px !important;
  }
}


.subCategoriesSection .swiper-slide {
  margin-right: 10px !important;
}

.serviceBookMarkBox{
  position: absolute;
  right: 4px;
  top: 4px;
  background-color: white;
  border-radius: 12px;
}

.fa-2xl {
  height: 35px;
  width: 35px;
}
.theme-btn {
  height: 50px;
  width: 55px;
}
.navbar-menu div button {
  font-size: 16px !important;
}


@media screen and (max-width: 905px) {
  .updateAddressModal{
    overflow: auto;
    height: 500px;
  }
}

.header-offcanvas .btn-close{
  background-color: aliceblue;
}
